// scripts/api.js
import axios from 'axios';
import CryptoJS from 'crypto-js';
import MiniSearch from 'minisearch'
import markersFallback from "/static/markers.json"

let miniSearch = new MiniSearch({
  fields: ['marques', 'nom', 'siteInternet'],
  storeFields: ['id','nom', 'position']
})

const trackRequest = async (store, position) => {

  if( sessionStorage.getItem('tracked') )
    return

  try {

    sessionStorage.setItem('tracked', '1');

    const reverse_api_url = 'https://api-adresse.data.gouv.fr/reverse/';
    const reverse_res = await axios.get(reverse_api_url, { params: {lat:position[0], lon:position[1]} });

    const address = reverse_res.data.features[0].properties;

    const coordinates = store.position[1]+','+store.position[0]+';'+position[1]+','+position[0]
    const route_api_url = 'https://router.project-osrm.org/route/v1/driving/'+coordinates+'?overview=false';

    const route_res = await axios.get(route_api_url);

    let data = {
      timestamp: new Date().getTime(),
      latLng: position,
      city: address.city,
      postCode: address.postcode,
      distance: Math.round(route_res.data.routes[0].distance/100)/10,
      duration: Math.round(route_res.data.routes[0].duration/60),
      id: store.id,
      reparationChaussures: store.reparationChaussures,
      reparationTextiles: store.reparationTextiles
    }

    const api_url = process.env.VUE_APP_API_URL + '/api/track';
    await axios.post(api_url, data);

  } catch (error) {

    sessionStorage.removeItem('tracked');
  }
}

const search = async (query, limit) => {

  const api_url = 'https://api-adresse.data.gouv.fr/search/';

  if (query.replace(/ /g, '').length < 3) return [];

  const params = {
    q: query,
    autocomplete: 1,
    limit: limit
  };

  trackEvent('search', {search_term:query});

  try {

    const res = await axios.get(api_url, { params: params });

    let addresses = [];
    res.data.features.forEach(feature => {
      if( feature.properties.score > 0.4 ){
        addresses.push({
          id: feature.properties.id,
          score: feature.properties.score,
          type: feature.properties.type,
          label: feature.properties.label,
          latLng: feature.geometry.coordinates.reverse()
        });
      }
    });

    let stores = [];
    miniSearch.search(query, { prefix: true, fuzzy: 0.2, combineWith: 'AND' }).forEach(store => {
      stores.push({
        id: store.id,
        score: store.score,
        type: 'store',
        label: store.nom,
        latLng: store.position
      });
    });

    if( !addresses.length && !stores.length )
      trackEvent('search_no_result', {search_term:query});

    return {addresses:addresses, stores: stores, length: addresses.length+stores.length};

  } catch (error) {

    return [];
  }
};

const autocomplete = async (query, limit) => {

  const api_url = 'https://api-adresse.data.gouv.fr/search/';

  if (query.replace(/ /g, '').length < 3) return [];

  const params = {
    q: query,
    autocomplete: 1,
    limit: limit
  };

  trackEvent('search', {search_term:query});

  try {

    const res = await axios.get(api_url, { params: params });

    let addresses = [];

    res.data.features.forEach(feature => {
      if( feature.properties.score > 0.4 ){
        addresses.push({
          id: feature.properties.id,
          score: feature.properties.score,
          type: feature.properties.type,
          label: feature.properties.label,
          latLng: feature.geometry.coordinates.reverse()
        });
      }
    });

    if( !addresses.length )
      trackEvent('search_no_result', {search_term:query});

    return addresses

  } catch (error) {

    return [];
  }
};

const getAllPoints = async () => {

  const api_url = process.env.VUE_APP_API_URL + '/api/map/all';

  try {

    const res = await axios.get(api_url);

    miniSearch.addAll(Object.values(res.data))
    return res.data

  } catch (error) {

    miniSearch.addAll(Object.values(markersFallback))
    return markersFallback
  }
}

const degreesToRadians = (degrees) =>{

  return degrees * Math.PI / 180;
}

/**
 * Computes the distance between two geographical coordinates using the Haversine formula.
 *
 * @param {Array<number>} markerPosition - The [latitude, longitude] of the first position (e.g., a marker).
 * @param {Array<number>} currentPosition - The [latitude, longitude] of the second position (e.g., current location).
 * @return {number} The distance between the two positions in meters.
 */
const computeDistance = (markerPosition, currentPosition) => {

  const lat1 = markerPosition[0];
  const lon1 = markerPosition[1];

  const lat2 = currentPosition[0];
  const lon2 = currentPosition[1];

  const R = 6371e3; // mètres
  const latRad1 = degreesToRadians(lat1);
  const latRad2 = degreesToRadians(lat2);
  const latDiff = degreesToRadians(lat2 - lat1);
  const lonDiff = degreesToRadians(lon2 - lon1);

  const a = Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
      Math.cos(latRad1) * Math.cos(latRad2) *
      Math.sin(lonDiff / 2) * Math.sin(lonDiff / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c * 1.5; // en mètres
}

const decode = (encryptedHex) => {

  // Extract the IV from the encrypted message
  const ivHex = encryptedHex.substring(0, 32);
  const iv = CryptoJS.enc.Hex.parse(ivHex);

  // Extract the ciphertext from the encrypted message
  const ciphertextBase64 = encryptedHex.substring(32);
  const ciphertext = CryptoJS.enc.Base64.parse(ciphertextBase64);

  // Create a key from the password
  const key = CryptoJS.SHA256(process.env.VUE_APP_SECRET_KEY);

  // Decrypt the ciphertext
  const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
  );

  // Convert the decrypted message to a string (utf8)
  return decrypted.toString(CryptoJS.enc.Utf8);
}

const trackEvent = (event_name, params)=>{

  if( event_name.length )
    params.event = event_name

  console.log('Track event : '+JSON.stringify(params))

  window.dataLayer.push(params);
}

const formatMarker = (marker) => {

  let type = []

  if( marker.serviceDomicile )
    type.push('serviceDomicile')

  if( marker.correspondance )
    type.push('correspondance')

  if( marker.displayAdresse )
    type.push('boutique')

  return {
    shop_name: marker.nom,
    cordoniers: marker.reparationChaussures,
    retoucheurs: marker.reparationTextiles,
    collect_type: type.join('|'),
    shop_city: marker.ville,
    shop_zip: marker.codePostal
  }
}

export {search, getAllPoints, computeDistance, decode, trackEvent, formatMarker, trackRequest, autocomplete}
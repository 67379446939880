<template>
  <transition :name="animation" @leave="reset">
    <aside class="c-sidebar" :class="'c-sidebar--'+currentRoute" v-if="active" ref="container">
      <div class="c-sidebar__inner" ref="scrollable" :class="{'c-sidebar__inner--keyboard':hasKeyboard, 'c-sidebar__inner--back':back, 'c-sidebar__inner--full':currentHeight>midHeight, 'c-sidebar__inner--scroll': scroll, 'c-sidebar__inner--drag': dragging }" :style="{ height: `${currentHeight}px` }" @touchstart="startDrag" @touchmove="onDrag" @touchend="endDrag">
        <transition name="fade">
          <a class="c-sidebar__back" v-if="back && !is_mobile" @click="navigate(-1)">{{ $t('store.back') }}</a>
        </transition>
        <transition name="fade">
          <a class="c-sidebar__close" v-if="(!is_mobile && !back) || (is_mobile && currentRoute !== 'home' )" @click="navigate('home')"></a>
        </transition>
        <div class="c-sidebar__content" ref="content">
          <router-view v-slot="{ Component }" name="sidebar">
            <transition :name="direction">
              <component :is="Component" :key="$route.params.id"/>
            </transition>
          </router-view>
        </div>
      </div>
    </aside>
  </transition>
</template>

<script>
export default {
  data() {
    return{
      dragging: false,
      wasActive: false,
      current: false,
      id: false,
      direction: 'left',
      forceDisplay: true,
      startY: 0,
      deltaY: 0,
      scrollTop: {},
      currentHeight: window.innerWidth < 1024 ? window.visualViewport.height - 80 : 81,
      innerHeight: window.visualViewport.height,
      minHeight: 81,
      hasKeyboard: false,
      dragDirection: 'top',
      scrollable: false
    }
  },
  computed:{
    hasFilters() {
      return this.$store.state.form.activities.length+this.$store.state.form.filters.length
    },
    is_mobile(){
      return window.innerWidth<1024
    },
    previousRoute(){
      return this.$store.state.previousRoute;
    },
    back(){
      return (this.direction === 'slide-left' && this.previousRoute === 'online-solutions') ||
          (this.previousRoute === 'store' && this.currentRoute === 'search-online' ) ||
          (this.previousRoute === 'search' && this.currentRoute === 'search-online') ||
          (this.previousRoute === 'search' && this.currentRoute === 'store') ||
          (this.previousRoute === 'home' && this.currentRoute === 'online-solutions') ||
          (this.previousRoute === 'search-online' && this.currentRoute === 'store') ||
          (this.forceDisplay && this.currentRoute === 'store')
    },
    animation(){
      return this.is_mobile ? 'slide-up' : 'slide'
    },
    mapMoving(){
      return this.$store.state.mapMoving;
    },
    position(){
      return this.$store.state.position
    },
    active() {
      return (this.wasActive || !this.mapMoving) && this.position.length && (this.$route.name !== 'home' || this.is_mobile || this.forceDisplay) && this.$route.matched.length ? 'sidebar' in this.$route.matched[0].components : false;
    },
    currentRoute() {
      return this.$route.name
    },
    settings() {
      return this.$route.matched[0].meta.sidebar
    },
    maxHeight(){
      return this.innerHeight - 80
    },
    midHeight(){
      return this.innerHeight/2 - 20
    },
    scroll(){
      return this.currentHeight === this.maxHeight
    }
  },
  watch:{
    currentHeight(){
      document.documentElement.style.setProperty('--sidebar-height', `${this.currentHeight}px`);
    },
    active(newValue, oldValue){

      if( newValue )
        this.wasActive = true

      if( oldValue !== newValue && newValue ){

        this.$nextTick(()=>{
          if( this.$refs.scrollable )
            this.scrollable = this.$refs.scrollable.querySelector('.c-sidebar__content > *')
        })
      }
    },
    hasFilters(){
      if( this.hasFilters )
        this.forceDisplay = true
    },
    $route(to, from){

      let isVisible = true;

      if( this.$refs.container )
        isVisible = this.$refs.container.getBoundingClientRect().top < this.innerHeight;

      if ( !isVisible )
        this.direction = 'none'
      else if(this.is_mobile && this.previousRoute === 'home' && (this.currentRoute === 'online-solutions' || this.currentRoute === 'filters' ) && this.currentHeight === this.minHeight )
        this.direction = 'fade'
      else
        this.direction = to.meta.depth < from.meta.depth ? 'slide-right': 'slide-left'

      if( this.scrollable )
        this.scrollTop[from.name] = this.scrollable.scrollTop

      this.$nextTick(()=>{

        if( !this.active )
          return

        if( this.$refs.scrollable ){

          let scrollables = this.$refs.scrollable.querySelectorAll('.c-sidebar__content > *')
          this.scrollable = scrollables.length === 2 ? scrollables[1] : scrollables[0]

          if(this.scrollable && to.name in this.scrollTop ){
            this.$nextTick(()=>{
              this.scrollable.scrollTop = this.scrollTop[to.name]
            })
          }
        }

        if( this.is_mobile ){

          if( this.settings ){

            if( 'init' in this.settings ){

              const targetHeight = this.settings.init === 'mid' ? this.midHeight : (this.settings.init === 'max' ? this.maxHeight : this.minHeight)

              if( this.currentHeight === this.minHeight )
                this.currentHeight = targetHeight
            }
          }
          else{

            if( this.currentHeight === this.minHeight )
              this.currentHeight = this.maxHeight
          }
        }
      })
    }
  },
  methods: {
    reset() {
      this.startY = 0;
      this.deltaY = 0;
      this.currentHeight = 81;
      this.minHeight = 81;
      this.dragging = false;
    },
    startDrag(event) {

      if( this.hasKeyboard )
        return

      this.dragging = true;
      document.body.classList.add('sidebar-dragging');

      this.deltaY = 0
      this.startY = event.touches[0].clientY;
    },
    onDrag(event) {

      if( this.scrollable && this.scrollable.scrollTop === 0 && this.is_mobile && this.dragging ) {

        this.deltaY = event.touches[0].clientY - this.startY;
        this.dragDirection = this.deltaY < 0 ? 'top' : 'bottom';

        this.currentHeight = Math.max(this.minHeight, Math.min(this.maxHeight, this.currentHeight - this.deltaY));
        this.startY = event.touches[0].clientY;
      }
    },
    endDrag() {

      this.dragging = false;
      document.body.classList.remove('sidebar-dragging');

      this.$nextTick(()=>{

        if( !this.scrollable )
          return;

        if( this.deltaY > 40 ){

            this.currentHeight = this.minHeight
            this.scrollable.scrollTop = 0
        }
        else if( this.deltaY < -40 ){

            this.currentHeight = this.maxHeight
            this.scrollable.scrollTop = 0
        }
        else if( this.currentHeight > this.midHeight && this.currentHeight < this.maxHeight ){

          this.currentHeight = this.dragDirection === 'top' ? this.maxHeight : this.midHeight
          this.scrollable.scrollTop = 0
        }
        else if( this.currentHeight < this.midHeight && this.currentHeight > this.minHeight ){

          this.currentHeight = this.dragDirection === 'top' ? this.midHeight : this.minHeight
          this.scrollable.scrollTop = 0
        }
      })
    },
    navigate(route){
      if( route === -1 ){

        this.$router.go(-1)
      }
      else{

        if( route === 'home')
          this.forceDisplay = false;

        this.$store.dispatch('resetPage')
        this.$router.push({name:route})
      }
    },
    catchResize(){

      this.hasKeyboard = window.visualViewport.height < this.innerHeight || window.visualViewport.offsetTop > 0
      this.innerHeight = window.visualViewport.height

      this.endDrag()
    }
  },
  mounted() {

    this.emitter.on("goTo", () => {
      this.currentHeight = this.midHeight
      this.forceDisplay = true
    });

    this.emitter.on("expandSidebar", () => {
      this.currentHeight = Math.min(this.innerHeight, this.maxHeight)
    });

    this.emitter.on("contractSidebar", () => {
      this.currentHeight = this.minHeight
    });

    this.emitter.on("inputFocus", (focus) => {
      if( focus )
        this.forceDisplay = false
    });

    if( this.$refs.scrollable )
      this.scrollable = this.$refs.scrollable.querySelector('.c-sidebar__content > *')

    document.documentElement.style.setProperty('--sidebar-height', `${this.currentHeight}px`);
  },
  created() {

    window.visualViewport.addEventListener('resize', this.catchResize);
    window.visualViewport.addEventListener('scroll', this.catchResize);
  },
  beforeUnmount() {

    window.visualViewport.removeEventListener('resize', this.catchResize);
    window.visualViewport.removeEventListener('scroll', this.catchResize);
  }
}
</script>

<style lang="scss">
.c-sidebar{
  position: absolute; left:0; padding-left: $space-m; bottom: $space; top: 8rem; width: 39rem; z-index: 10001;
  @media #{$to-phone} {
    padding-left: 0; bottom: 0; top: auto; width: 100%; transition: allow(transform); max-width: 43rem;
    left: auto; right: 0;
  }
  .c-worldmap.has-popup~&{ transform: translateY(calc(100% + 1rem)) }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
    display: inline-block; width: 3rem!important; height: 3rem; background-size: 60%; left: auto!important;
    position: absolute; right: $space-s!important; top: $space-s!important; cursor: pointer; z-index: 1;
  }
  &__back{
    display: inline-flex; align-items: center; grid-gap: $space-s; cursor: pointer; z-index: 1;
    position: absolute; left: $space-s; top: $space-s;
    &:before{
      content: ''; display: inline-block; width: 3rem; height: 3rem; border-radius: 50%;
      background: url("../assets/picto/arrow-back.svg") no-repeat center $c-white; background-size: 50%;
    }
  }
  &__inner{
    height: 100%; position: relative; overflow: hidden;
    background: $c-background; border-radius: $space; box-shadow: $box-shadow;
    @media #{$from-phone} { height: 100%!important }
    @media #{$to-phone} {
      border-radius: $radius $radius 0 0; height: 0; box-shadow: $box-shadow-invert;
      transition: allow(height); max-height: calc($app-h - 80px);
      &--drag{ transition: none }
      &:after{
        content: ''; background: rgba(0,0,0,0.06); border-radius: 16px; display: inline-block; width: 10%; height: 4px;
        position: absolute; top: $space-xs; left: 50%; margin-left: -5%;
      }
      &--scroll .c-sidebar__content > *{ overflow: auto; overflow-x: hidden }
    }
  }
  &__content{
    height: 100%;
    >*{
      height: 100%; padding: $space-m 16px $space $space; overflow: auto; width: 100%; overflow-x: hidden;
      overscroll-behavior: none;
      @include scrollbar(4px, rgba(0,0,0,0.05), rgba(0,0,0,0), 4px, 4px);
      &:hover{
        @include scrollbar(4px, rgba(0,0,0,0.2), rgba(0,0,0,0), 4px, 4px)
      }
      @media #{$to-phone} { overflow: hidden }
    }
  }
}
</style>

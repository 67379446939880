import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            markers: {},
            filteredMarkers: [],
            map: false,
            mapMoving: false,
            query: '',
            page: {
                omnibox:1
            },
            previousRoute: '/',
            search_results: {
                addresses:[],
                stores: [],
                length: 0
            },
            loading: true,
            searching: false,
            position:[],
            recent_search: [],
            form: {
                gps:false,
                activities:[],
                filters:[]
            },
        }
    },
    mutations: {
        markers (state, markers) {

            state.markers = markers
            state.loading = false
        },
        filteredMarkers (state, markers) {

            state.filteredMarkers = markers
        },
        previousRoute (state, previousRoute) {

            state.previousRoute = previousRoute
        },
        query (state, query) {

            state.query = query
        },
        page (state, data) {

            state.page[data.id] = data.page
        },
        map (state, map) {

            state.map = map
        },
        mapMoving (state, value) {

            state.mapMoving = value
        },
        form (state, form) {

            state.form = form
        },
        search_results (state, search_results) {

            state.search_results = search_results
            state.loading = false
        },
        searching (state, searching) {

            state.searching = searching
        },
        loading (state, loading) {

            state.loading = loading
        },
        position (state, position) {

            if( JSON.stringify(state.position) !== JSON.stringify(position)){

                sessionStorage.removeItem('tracked');
                localStorage.setItem('position', JSON.stringify(position));

                state.position = position
            }
        },
        recent_search(state, recent_search) {

            if( !state.recent_search.some(obj => obj.id === recent_search.id) ){

                state.recent_search.unshift(recent_search);

                if (state.recent_search.length > 10)
                    state.recent_search.pop();
            }

            localStorage.setItem('search', JSON.stringify(state.recent_search));
        },
    },
    actions:{
        resetPage({ state }) {
            state.page = {
                omnibox: 1
            }
        },
        load({ state }) {

            let recent_search = localStorage.getItem('search');

            if( recent_search && recent_search.length )
                state.recent_search = JSON.parse(recent_search);

            let position = localStorage.getItem('position');

            if( position && position.length )
                state.position = JSON.parse(position);
        }
    }
})

export default store
<template>
  <section class="c-worldmap" :class="{'has-popup':marker}">
    <l-map :useGlobalLeaflet="true" @ready="onMapReady" v-model:zoom="zoom" v-model:center="center" :options="options" @update:center="mapUpdated">
      <l-tile-layer :url="tileProvider" layer-type="base" name="OpenStreetMap"></l-tile-layer>
      <l-control-zoom position="bottomright"></l-control-zoom>
      <l-marker :icon="icons.home" :lat-lng="position" v-if="position.length"></l-marker>
      <l-marker-cluster-group :showCoverageOnHover="false" :maxClusterRadius="100" v-if="displayedMarkers.length" @ready="onClusterReady">
        <l-marker :icon="getIcon(marker)" :name="marker.id" :lat-lng="marker.position" v-for="marker in displayedMarkers" :key="marker.id" @click="show(marker)">
          <l-popup :options="popup_options">
            <div class="c-worldmap__item" v-if="!is_mobile">
              <StoreSummary :order="0" :marker="marker"/>
              <div class="c-worldmap__item-actions">
                <a class="c-worldmap__item-action c-worldmap__item-action--phone" @click="track(marker, 'telephone')" :href="'tel:'+marker.telephone" v-if="marker.telephone.length">{{ $t('store.call') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--mail" @click="track(marker, 'email')" :href="'mailto:'+decode(marker.email)" target="_blank" v-if="marker.email.length">{{ $t('store.write') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--gps" v-if="marker.displayAdresse" @click="openNavigationSelector(marker)">{{ $t('store.go') }}</a>
              </div>
              <router-link class="c-worldmap__item-more" :to="{name:'store', params:{id:marker.id}}" @click="closePopup">{{ $t('map.more') }}</router-link>
            </div>
          </l-popup>
        </l-marker>
      </l-marker-cluster-group>
      <div class="c-worldmap__gps" v-if="hasGeolocation">
        <a class="c-worldmap__gps-button" @click="detectPosition" v-track-button="{location:'map'}">{{ $t('map.gps_button') }}</a>
        <div class="c-worldmap__gps-info">{{ $t('map.gps') }}</div>
      </div>
    </l-map>
    <transition name="slide-up">
      <div class="c-worldmap__popup" v-if="marker && is_mobile">
        <div class="c-worldmap__item c-worldmap__item--mobile">
          <a class="c-worldmap__item-close" @click="closePopup"></a>
          <transition name="slide-left">
            <div class="c-worldmap__item-wrapper" v-if="marker && is_mobile" :key="marker.id">
              <StoreSummary :order="0" :marker="marker"/>
              <div class="c-worldmap__item-actions">
                <a class="c-worldmap__item-action c-worldmap__item-action--phone" @click="track(marker, 'telephone')" :href="'tel:'+marker.telephone" v-if="marker.telephone.length">{{ $t('store.call') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--mail" @click="track(marker, 'email')" :href="'mailto:'+decode(marker.email)" target="_blank" v-if="marker.email.length">{{ $t('store.write') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--gps" v-if="marker.displayAdresse" @click="openNavigationSelector(marker)">{{ $t('store.go') }}</a>
              </div>
              <router-link class="c-worldmap__item-more" :to="{name:'store', params:{id:marker.id}}" @click="closePopup">{{ $t('map.more') }}</router-link>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    <a class="c-worldmap__iframe" @click="expand" v-if="is_iframe">{{ $t('map.iframe') }}</a>
  </section>
</template>

<script>

import "leaflet/dist/leaflet.css";
import 'vue-leaflet-markercluster/dist/style.css'

import L from 'leaflet'
window.L = L

import { LMap, LTileLayer, LControlZoom, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'
import { icon } from "leaflet";

import shoes from "../assets/marker/shoes.svg";
import shoes_active from "../assets/marker/shoes_active.svg";
import clothes from "../assets/marker/clothes.svg";
import clothes_active from "../assets/marker/clothes_active.svg";
import both from "../assets/marker/both.svg";
import both_active from "../assets/marker/both_active.svg";
import home from "../assets/marker/home.svg";
import StoreSummary from "@/components/StoreSummary.vue";
import {computeDistance, decode, trackEvent} from "@/scripts/api";

export default {
  components: {
    StoreSummary,
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LPopup,
    LMarkerClusterGroup
  },
  data() {

    let position = localStorage.getItem('position');
    const tileProvider = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}'+ (L.Browser.retina ? '@2x.png' : '.png');

    return {
      tileProvider: tileProvider,
      markerClusterGroup: false,
      timeout: false,
      instance: false,
      is_iframe: window.top !== window.self,
      zoom: position && position.length ? 13 : (window.innerWidth<1024 ? 6 : 7),
      loading: false,
      hasGeolocation: "geolocation" in navigator,
      center:{lat:47.266667, lng:2.233333},
      popup_options:{
        maxWidth:330,
        offset:[0,0],
        autoPanPaddingTopLeft:[55,100]
      },
      options:{
        zoomControl: false,
        minZoom:4
      },
      marker:false,
      icons:{
        home: icon({
          iconUrl: home,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        shoes: icon({
          iconUrl: shoes,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        shoes_active: icon({
          iconUrl: shoes_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        clothes: icon({
          iconUrl: clothes,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        clothes_active: icon({
          iconUrl: clothes_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        both: icon({
          iconUrl: both,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        both_active: icon({
          iconUrl: both_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        })
      }
    }
  },
  watch:{
    filteredMarkers(){

      this.$store.commit('filteredMarkers', this.filteredMarkers);

      this.mapUpdated()
    },
    $route(to){
      if( to.name === 'home')
        this.$nextTick(this.mapUpdated)
    }
  },
  computed:{
    is_mobile(){
      return window.innerWidth<1024
    },
    markers(){
      return this.$store.state.markers;
    },
    position(){
      return this.$store.state.position;
    },
    searching(){
      return this.$store.state.searching;
    },
    id(){
      return this.$route.params.id
    },
    form() {
      return this.$store.state.form
    },
    search_results(){
      return this.$store.state.search_results.stores.map(item=>item.id);
    },
    filteredMarkers(){

      if( !this.form.activities.length && !this.form.filters.length ){

        return Object.values(this.markers).filter(marker=>{
          return !this.searching || this.search_results.indexOf(marker.id) >= 0
        })
      }
      else{

        let match = {
          shoes: 'reparationChaussures',
          clothes: 'reparationTextiles',
          stores: 'displayAdresse',
          services: 'serviceDomicile',
          change: 'correspondance'
        }

        return Object.values(this.markers).filter(marker=>{
          return (this.form.activities.every(key => marker[match[key]] === true) && this.form.filters.every(key => marker[match[key]] === true)) && (!this.searching || marker.id in this.search_results);
        })
      }
    },
    displayedMarkers(){

      return this.filteredMarkers.filter(marker=>{
        return marker.displayAdresse
      })
    }
  },
  methods:{
    decode,
    track(marker, event){

      trackEvent(event, {
        event: 'contact',
        contact_type: event,
        shop_name: marker.nom,
        shop_city: marker.ville,
        shop_zip: marker.codePostal,
        button_location: 'map'
      })
    },
    onMapReady(instance) {

      this.instance = instance;
      this.$store.commit('map', instance);

      if( this.position.length )
        this.center = {lat:this.position[0], lng:this.position[1]}

      instance.on("zoomstart", ()=>this.$store.commit('mapMoving', true));
      instance.on("zoomend", ()=>this.$store.commit('mapMoving', false));

      this.emitter.emit('mapReady');
    },
    mapUpdated() {

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.emitter.emit('mapUpdated');
      },100)
    },
    onClusterReady(markerClusterGroup) {

      this.markerClusterGroup = markerClusterGroup;
    },
    expand(){

      window.parent.postMessage({ action: 'goFullscreen' }, '*');
    },
    show(marker){

      if( this.$route.name === 'store' && this.is_mobile ) {

        this.$router.push({name:'store', params:{id:marker.id}})
      }
      else{

        if( this.is_mobile )
          this.marker = marker

        //this.$router.push({name:'home'})
      }
    },
    openNavigationSelector(marker){

      this.track(marker, 'gps')
      this.emitter.emit('openNavigationSelector', marker)
    },
    closePopup(){

      this.instance.closePopup();
      this.marker = false;
    },
    flyTo(latLng, zoom){

      const distance = computeDistance([this.center.lat, this.center.lng], [latLng.lat, latLng.lng])
      const duration = Math.max(0.4, Math.min(2, 0.4*(1+distance/10000)));

      this.instance.flyTo(latLng, zoom, {duration:duration});
    },
    detectPosition(){

      navigator.geolocation.getCurrentPosition((position) => {

        this.flyTo({lat:position.coords.latitude, lng:position.coords.longitude}, 13);
        this.$store.commit('position', [position.coords.latitude, position.coords.longitude]);

      }, (error)=>{

        alert(error.message);
      });
    },
    getIcon(marker){

      if( marker.reparationChaussures && marker.reparationTextiles )
        return (this.id===marker.id||this.marker.id===marker.id)?this.icons.both_active:this.icons.both
      else if( marker.reparationChaussures )
        return (this.id===marker.id||this.marker.id===marker.id)?this.icons.shoes_active:this.icons.shoes
      else
        return (this.id===marker.id||this.marker.id===marker.id)?this.icons.clothes_active:this.icons.clothes
    }
  },
  mounted() {

    this.emitter.on("goTo", address => {
      this.flyTo({lat:address.latLng[0], lng:address.latLng[1]}, 'zoom' in address ? address.zoom : this.zoom )
    });

    this.emitter.on("detectPosition", this.detectPosition);
  }
}
</script>

<style lang="scss">
.c-worldmap{
  width: 100%; height: 100%; position: relative;
  .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    box-shadow: $box-shadow; border: 0;
  }
  .leaflet-control-zoom-in, .leaflet-control-zoom-out{
    background: $c-white no-repeat center; background-size: 60%;
    span{ display: none }
  }
  .leaflet-control-zoom-in{ background-image: url("../assets/picto/plus.svg") }
  .leaflet-control-zoom-out{ background-image: url("../assets/picto/minus.svg") }
  &__gps{
    &-button{
      position: absolute; right: $space-m; bottom: calc($space + 84px); width: 30px; height: 30px; z-index: 9999;
      background: url("../assets/picto/gps.svg") no-repeat center #fff; border-radius: 4px; font-size: 0; color: transparent;
      box-sizing: initial; cursor: pointer; background-size: 60%; box-shadow: $box-shadow; transition: allow(opacity bottom);
      .route-welcome &{ opacity: 0 }
      &:hover{ background-color:#f4f4f4 }
      @media #{$to-phone} {
        right: $space; bottom: min(50vh, var(--sidebar-height)); margin-bottom: 9rem;
        .sidebar-dragging &{ transition: none}
      }
    }
    &-info{
      position: absolute; right: calc($space-m + 50px); bottom: calc($space-m + 10px); width: 42rem; height: 90px; z-index: 9999; opacity: 0;
      background: url("../assets/picto/gps.svg") no-repeat 90% 50% #fff; background-size: 3rem; border-radius: 1rem; pointer-events: none;
      font-size: $font-m; padding: 0 4rem; display: flex; align-items: center; text-align: right; padding-right: 10rem;
      @media #{$to-phone} { display: none }
      }
    &:hover &-info{ opacity: 1 }
  }
  &__iframe{
    position: absolute; left: $space-m ; bottom: $space; background: $c-black; color: $c-white; border-radius: $space;
    z-index: 9997; padding: $space-s $space; display: inline-flex; align-items: center; cursor: pointer;
    &:before{
      display: inline-block; width: 2rem; height: 2rem; content: ''; background: url("../assets/picto/frame.svg") no-repeat center;
      background-size: contain; margin-right: 1rem;
    }
  }
  &__popup{
    position: absolute; left: 0; bottom:0; right: 0; z-index: 10000; width: auto; padding: $space;
  }
  &__item{
    width: 33rem; background: $c-background; padding: $space; border-radius: $space; box-shadow: $box-shadow; display: flex;
    flex-flow: column; margin-left: -5px; position: relative;
    &--mobile{
      @media #{$to-phone} { overflow: hidden; width: auto; margin-left: 0; padding: 0 }
    }
    .c-store-summary{
      &__more, &__cta{ display: none }
    }
    &-close{
      background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
      display: inline-block; width: 3rem; height: 3rem; background-size: 60%; z-index: 1;
      position: absolute; right: $space-s; top: $space-s; cursor: pointer;
    }
    &-wrapper{ position: relative; overflow: hidden; padding: $space; display: flex; flex-flow: column; justify-content: center }
    &-actions{ display: flex; grid-gap: $space-s; border-top: 1px solid $c-border; padding-top: $space; margin: $space 0 }
    &-action{
      background: $c-white; padding: $space-xs; padding-right: $space; border-radius: $radius; flex-shrink: 0; grid-gap: $space-xs;
      font-size: $font-xs; font-weight: 500; cursor: pointer; transition: allow(box-shadow); display: inline-flex; align-items: center;
      &:hover{ box-shadow: $box-shadow }
      &:before{
        display: inline-block; width: 1.6rem; height: 1.6rem; flex-shrink: 0;
        background: no-repeat center; background-size: contain; content: '';
      }
      &--gps:before{ background-image: url("../assets/picto/pin.svg") }
      &--phone:before{ background-image: url("../assets/picto/phone.svg") }
      &--mail:before{ background-image: url("../assets/picto/mail.svg") }
    }
    &-more{ font-size: $font-xs; text-decoration: underline; margin: 0 auto }
  }

  .marker-cluster{
    background: $c-white; border: 1px solid; border-radius: 50%; display: inline-flex; align-items: center;
    justify-content: center; font-size: $font-xs; font-weight: 500;
    &-medium{
      width: 80px!important; height: 80px!important; margin-left: -40px!important; margin-top: -40px!important;
      font-size: $font-s; font-weight: 600;
    }
  }

  .leaflet{
    &-container a.leaflet-popup-close-button{
      background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
      display: inline-block; width: 3rem; height: 3rem; background-size: 60%; z-index: 1;
      position: absolute; right: $space-s; top: $space-s; cursor: pointer;
      span{ display: none }
    }
    &-control-zoom{
      margin-right: $space-m; margin-bottom: $space; transition: allow(opacity bottom);
      .route-welcome &{ opacity: 0 }
      @media #{$to-phone} {
        margin-right: $space; margin-bottom: 0; bottom: min(50vh, var(--sidebar-height));
        .sidebar-dragging &{ transition: none}
      }
    }
    &-control-attribution{ font-size: 9px; padding: 1px 10px; background: none; opacity: 0.4 }
    &-attribution-flag{ display: none!important }
    &-popup{
      margin-bottom: 77px; margin-left: -20px;
      @media #{$to-phone} { display: none }
    }
    &-popup-tip{ background: $c-background; box-shadow: $box-shadow }
    &-popup-content p{ margin: 0 }
    &-popup-content, &-container{ font-size: inherit; line-height: inherit; font-family: inherit; margin: 0; width: auto!important }
    &-popup-content-wrapper, .leaflet-popup-tip{ background: none; box-shadow: none }
    &-container a{ color: inherit }
    &-marker-pane{
      transition: allow(opacity);
      .route-welcome &{ opacity: 0 }
    }
  }

}
</style>

<template>
  <div class="c-online-solutions c-online-solutions--search">
    <h2 class="c-online-solutions__title">{{ $t('online.title') }}</h2>
    <div class="c-online-solutions__no-results" v-if="loading">
      <div class="c-online-solutions__message c-online-solutions__message--searching">{{ $t('search.searching') }}</div>
    </div>
    <div class="c-online-solutions__items">
      <StoreSummary v-for="marker in solutions" :marker="marker" :key="marker.id"/>
    </div>
  </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";
import {formatMarker, trackEvent} from "@/scripts/api";

export default {
  components: {
    StoreSummary
  },
  computed: {
    markers() {
      return this.$store.state.markers;
    },
    solutions() {
      return this.$store.state.search_results.stores.map(this.getMarker).filter(marker => {
        return marker.correspondance || marker.typeEntiteLegale === 'DIGITALE'
      });
    },
    loading(){
      return this.$store.state.loading;
    }
  },
  methods: {
    trackView() {
      if (this.solutions && this.solutions.length)
        trackEvent('view_online_solutions_list', {items: this.solutions.map(formatMarker)})
    },
    getMarker(result) {
      return this.markers[result.id]
    }
  },
  watch: {
    solutions() {
      this.trackView()
    }
  },
  mounted() {
    this.trackView()
  }
}
</script>


<style lang="scss">
.c-online-solutions{
  .c-sidebar__back~.c-sidebar__content &--search{ padding-top: 5rem }
  &__message{
    text-align: center; margin: $space 0; line-height: 1.6; padding: 0 $space; display: flex; flex-flow: column;
    align-items: center;
    &:before{
      content: ''; width: 6rem; height: 6rem; background: url("../assets/picto/smiley.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: $space
    }
    &--searching:before{
      background-image: url("../assets/picto/loader.svg")
    }
  }
}
</style>
<template>
  <div class="c-searchbox" :class="{'has-results':hasResults}" v-click-outside="close">
    <h2 class="c-searchbox__title">
      {{ $t('search.title') }}
      <a class="c-searchbox__close" @click="close"></a>
    </h2>
    <SearchForm @input-focus="setFocus"/>
    <SolutionFilters v-if="is_mobile"/>
    <div class="c-searchbox__results">
      <slideUpDown v-model="hasResults" :duration="300">
        <slideUpDown v-model="search_results.length" :duration="300">
          <div class="c-searchbox__results-group" :class="{'has-more':more.search_results}">
            <div class="c-searchbox__results-title">{{ $t('search.suggest') }}</div>
            <div class="c-searchbox__results-items">
              <a class="c-searchbox__result" v-for="item in search_results.addresses" :key="item.id" v-html="highlight(item.label)" @click="goTo(item)"></a>
            </div>
            <div class="c-searchbox__results-items">
              <a class="c-searchbox__result" v-for="item in search_results.stores" :key="item.id" v-html="highlight(item.label)" @click="goTo(item)"></a>
            </div>
            <a class="c-searchbox__results-more" @click="moreResults('search_results')" v-if="search_results.length > 2">
              <span v-if="more.search_results">{{ $t('search.less') }}</span>
              <span v-else>{{ $t('search.more') }}</span>
            </a>
          </div>
        </slideUpDown>
        <slideUpDown v-model="recent_search.length" :duration="300">
          <div class="c-searchbox__results-group" :class="{'has-more':more.recent_search}">
            <div class="c-searchbox__results-title">{{ $t('search.recent') }}</div>
            <div class="c-searchbox__results-items">
              <a class="c-searchbox__result" v-for="item in recent_search" :key="item.id" v-html="highlight(item.label)" @click="goTo(item)"></a>
            </div>
            <a class="c-searchbox__results-more" @click="moreResults('recent_search')" v-if="recent_search.length > 2">
              <span v-if="more.recent_search">{{ $t('search.less') }}</span>
              <span v-else>{{ $t('search.more') }}</span>
            </a>
          </div>
        </slideUpDown>
      </slideUpDown>
    </div>
  </div>
</template>

<script>

import SearchForm from "@/components/SearchForm.vue";
import SolutionFilters from "@/components/SolutionFilters.vue";

export default {
  components:{
    SearchForm,
    SolutionFilters
  },
  data() {
    return {
      has_focus: false,
      more: {
        search_results:false,
        recent_search:false
      }
    }
  },
  computed:{
    markers(){
      return this.$store.state.markers
    },
    is_mobile(){
      return window.innerWidth<1024
    },
    searching(){
      return this.$store.state.searching;
    },
    query(){
      return this.$store.state.query
    },
    recent_search(){
      return this.$store.state.recent_search
    },
    search_results(){
      return this.$store.state.search_results
    },
    hasResults(){
      return this.has_focus && ( this.search_results.length || this.recent_search.length )
    },
    loading(){
      return this.$store.state.loading;
    }
  },
  methods:{
    setFocus(focus){

      this.$emit('input-focus', focus)
      this.emitter.emit('inputFocus', focus)

      this.has_focus = focus
    },
    close(){

      if( this.is_mobile )
        return;

      this.setFocus(false)
    },
    moreResults(type){
      this.more[type] = !this.more[type]
    },
    goTo(address){

      this.$store.commit('recent_search', address)

      if( !this.is_mobile )
        this.close()

      if( address.type === 'store' ){

        this.$router.push({ name: 'store', params: { id: address.id } })
      }
      else{

        this.$store.commit('position', address.latLng);

        if( address.type === 'municipality' )
          this.emitter.emit('goTo', {latLng:address.latLng, zoom:13})
        else
          this.emitter.emit('goTo', {latLng:address.latLng, zoom:14})
      }
    },
    highlight(label){
      const regEx = new RegExp(this.query, "ig");
      return label.replace(regEx, (match) => {
        return '<b>' + match + '</b>';
      })
    },
    watchEscapeKey(){

      document.addEventListener('keyup', (evt)=> {
        if (evt.key === "Escape")
          this.close()
      });
    }
  },
  mounted() {

    this.watchEscapeKey()
  }
}
</script>

<style lang="scss">
.c-searchbox{
  height: 100%; flex-grow: 1; position: relative; pointer-events: none;
  @media #{$to-phone} {
    position: absolute; top: calc($app-h - 14rem); left: 0; right: 0; z-index: 9999; padding: $space;
    transition: allow(top background);
    &.has-results{ top: 0; background: $c-background }
  }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
    display: inline-block; width: 3rem; height: 3rem; background-size: 60%;
    position: absolute; right: $space-s; top: $space-s; cursor: pointer;
  }
  &__title{
    @extend %title-section; margin-bottom: $space; opacity: 0; transition: allow(opacity);
    @media #{$from-phone} { display: none }
    .has-results &{ opacity: 1; pointer-events: all }
  }
  .c-solution-filters{
    width: calc(100% + 2*$space); margin-left: -$space; padding: 0 $space; margin-top: $space; pointer-events: all;
    overflow: hidden; height: auto;
    &__items{
      width: fit-content;
      &:before{
        content: ''; width: 2.8rem; height: 2.8rem; background: url("../assets/picto/filter.svg") no-repeat center;
        background-size: 70%; display: inline-block; flex-shrink: 0;
      }
    }
    &__item{
      &--online{
        display: none
      }
    }
  }
  &__results{
    position: absolute; top: 0; width: 100%; background: $c-white; display: flex; flex-flow: column;
    z-index: 1; border-radius:$radius; box-shadow: $box-shadow; grid-gap: $space-m;
    pointer-events: all; padding-top: 4rem;
    @media #{$to-phone} { position: static; box-shadow: none; background: none; padding: 0 }
    &-group{
      position: relative; padding: $space; padding-top: $space-s;
      @media #{$to-phone} { padding:0; padding-top: $space-l }
      .slide-up-down__container+.slide-up-down__container &{
        padding-top: 0;
        .c-searchbox__results-more{ top: 0 }
        @media #{$to-phone} { padding-top: $space }
      }
    }
    &-items{
      &+&{ border-top: 1px solid $c-border }
      max-height: 7rem; transition: allow(max-height); overflow: hidden; display: flex; flex-flow: column;
      .has-more &{
        max-height: 21.5rem;
        @media #{$to-phone} { max-height: 22.5rem }
      }
    }
    &-more{
      position: absolute; right: $space; top: $space-s; color: $c-grey; cursor: pointer;
      @media #{$to-phone} { position: static; margin-top: $space; display: block }
      &:after{
        content: ''; width: 12px; height: 8px; background: url("../assets/picto/arrow-down.svg") no-repeat center;
        background-size: contain; display: inline-block; margin-left: $space-s; transition: allow(transform);
        .has-more &{ transform: scaleY(-1) }
      }
    }
    &-title{
      font-size: $font-m; font-weight: 600; margin-bottom: $space-s;
    }
  }
  &__result{
    padding: $space-s 0; cursor: pointer; transition: allow(color);
    &:hover{ color: $c-theme }
    b{ font-weight: 600 }
    &+&{ border-top: 1px solid $c-border }
  }
}
</style>

<template>
  <div class="c-omnibox" ref="scrollabe">
    <SearchBox @input-focus="setFocus" v-if="is_mobile"/>
    <h2 class="c-omnibox__title" v-else>{{ $t('search.results') }}</h2>
    <transition name="fade">
      <div class="c-omnibox__container" v-if="!search_focus">
        <div class="c-omnibox__items" v-if="visibleMarkers.length">
          <router-link v-track="{event:'solution_online_clicked', button_location:'search_results'}" v-if="solutions.length" class="c-omnibox__online" :to="{name:'online-solutions'}">
            <span v-html="$t('search.online', {count:solutions.length})"></span>
            <span class="c-omnibox__online-more">{{ $t('online.see') }}</span>
          </router-link>
          <StoreSummary :order="index" v-for="(marker, index) in displayedMarkers" :marker="marker" :key="marker.id"/>
          <a class="c-omnibox__more" @click="nextPage" v-if="visibleMarkers>displayedMarkers">{{ $t('search.more') }}</a>
        </div>
        <div class="c-omnibox__no-results" v-else-if="loading">
          <div class="c-omnibox__message c-omnibox__message--searching">{{ $t('search.searching') }}</div>
        </div>
        <div class="c-omnibox__no-results" v-else>
          <div class="c-omnibox__message">{{ $t('search.error') }}</div>
          <router-link class="c-omnibox__cta c-omnibox__cta--online" :to="{name:'online-solutions'}" v-if="solutions.length" v-track="{event:'solution_online_clicked', button_location:'search_results'}">{{ $t('search.see_online') }}</router-link>
          <a class="c-omnibox__cta c-omnibox__cta--search" v-track-button="{location:'search_results'}" @click="editSearch">{{ $t('search.edit') }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";
import SearchBox from "@/components/SearchBox.vue";
import {computeDistance, formatMarker, trackEvent, trackRequest} from "@/scripts/api";

export default {
  components: {
    SearchBox,
    StoreSummary
  },
  data() {
    return{
      search_focus: false,
      visibleMarkers: []
    }
  },
  watch:{
    filteredMarkers(){
      this.updateMarkers()
    },
    displayedMarkers(oldValue, newValue){
      if( JSON.stringify(oldValue) !== JSON.stringify(newValue) )
        this.$nextTick(this.trackView)
    }
  },
  computed:{
    is_mobile(){
      return window.innerWidth<1024
    },
    search_results(){
      return this.$store.state.search_results.stores
    },
    position(){
      return this.$store.state.position;
    },
    searching(){
      return this.$store.state.searching;
    },
    loading(){
      return this.$store.state.loading;
    },
    filteredMarkers(){
      return this.$store.state.filteredMarkers;
    },
    displayedMarkers(){
      return this.visibleMarkers.slice(0, this.page*20)
    },
    map(){
      return this.$store.state.map;
    },
    mapMoving(){
      return this.$store.state.mapMoving;
    },
    page(){
      return this.$store.state.page.omnibox;
    },
    solutions(){
      return Object.values(this.filteredMarkers).filter(marker=>{
        return marker.correspondance || marker.typeEntiteLegale==='DIGITALE'
      })
    }
  },
  methods: {
    nextPage() {
      this.$store.commit('page', {id:'omnibox', page: this.page + 1 })
    },
    trackView(){

      if( this.visibleMarkers && this.visibleMarkers.length && !this.mapMoving ){

        trackEvent('view_shop_list',{items: this.visibleMarkers.map(formatMarker)})
        trackRequest(this.visibleMarkers[0], this.position)
      }
    },
    updateMarkers() {

      this.$store.commit('page', {id:'omnibox', page: 1})

      if( this.$refs.scrollabe )
        this.$refs.scrollabe.scrollTop = 0

      if( !this.map || !this.filteredMarkers )
        return []

      this.visibleMarkers = this.filteredMarkers.filter(marker => {
        return marker.typeEntiteLegale !== 'DIGITALE' && marker.displayAdresse && this.map.getBounds().contains(marker.position)
      }).sort((a,b)=>{
        return computeDistance(a.position, this.position)>computeDistance(b.position, this.position)?1:-1
      })
    },
    setFocus(focus){

      if( focus )
        this.emitter.emit('expandSidebar');

      this.search_focus = focus
    },
    editSearch(){

      this.$router.push({name:'home'})
      this.emitter.emit('searchFocus');
    },
  },
  mounted() {

    this.emitter.on("mapUpdated", this.updateMarkers)
    this.$nextTick(this.updateMarkers)
  },
  unmounted() {

    this.emitter.off("mapUpdated", this.updateMarkers)
  }
}
</script>

<style lang="scss">
.c-omnibox{
  display: flex; flex-flow: column; grid-gap: $space;
  @media #{$to-phone} { padding-top: $space }
  &__online{
    background: $c-black; color: $c-white; border-radius: $space; padding: $space; display: flex; grid-gap: $space;
    align-items: center; order: 2; cursor: pointer;
    &-more{
      display: inline-flex; align-items: center; grid-gap: $space-s; font-weight: 600;
      &:after{
        background: url("../assets/picto/arrow-white.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
        height: 1em; display: inline-block;
      }
    }
  }
  &__items{
    display: flex; flex-flow: column; grid-gap: $space; padding: $space 0;
  }
  .c-searchbox{
    position: static; padding: 0; flex-grow: 0; height: auto;
    &__title{ display: none }
    .c-searchform{ box-shadow: none }
  }
  &__title{ @extend %title-section }
  &__cta{
    padding: $space; border-radius: $radius; background: $c-white; text-align: center; font-weight: 600;
    display: flex; align-items: center; grid-gap: $space-s; justify-content: center; cursor: pointer;
    &--online{ background: $c-black; color: $c-white; margin-bottom: $space-s }
    &--search{
      &:before{
        content: ''; width: 2rem; height: 2rem; background: url("../assets/picto/magnifier.svg") no-repeat center;
        background-size: contain; display: inline-block; margin-right: $space-s
      }
    }
  }
  &__online{
    background: $c-black; color: $c-white; border-radius: $space-s; padding: $space; display: flex; grid-gap: $space;
    align-items: center; order: 4; cursor: pointer;
    &-more{
      display: inline-flex; align-items: center; grid-gap: $space-s; font-weight: 600;
      &:after{
        background: url("../assets/picto/arrow-white.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
        height: 1em; display: inline-block;
      }
    }
    &~.c-store-summary--4{ border-top: 0; padding-top: 0 }
  }
  &__more{
    background: $c-white; padding: $space-xs; display: block; text-align: center; border-radius: $space; order: 99999;
    margin-top: $space; cursor: pointer;
  }
  &__message{
    text-align: center; margin: $space 0; line-height: 1.6; padding: 0 $space; display: flex; flex-flow: column;
    align-items: center;
    &:before{
      content: ''; width: 6rem; height: 6rem; background: url("../assets/picto/smiley.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: 0; transition: allow(height margin); margin-bottom: $space;
      @media #{$to-phone} {
        height: 0; margin-bottom: 0;
        .c-sidebar__inner--full &{ margin-bottom: $space; height: 6rem }
      }
    }
    &--searching:before{
      background-image: url("../assets/picto/loader.svg")
    }
  }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
    display: inline-block; width: 3rem; height: 3rem; background-size: 60%;
    position: absolute; right: $space-s; top: $space-s; cursor: pointer; z-index: 1;
  }
}
</style>

<template>
  <div class="c-mobile-filters">
    <router-link class="c-mobile-filters__close" :to="{name:'home'}"></router-link>
    <h2 class="c-mobile-filters__title">{{ $t('filters.title') }}</h2>
    <h3 class="c-mobile-filters__subtitle">{{ $t('filters.subtitle_repair') }}</h3>
    <div class="c-mobile-filters__items c-mobile-filters__items--repair">
      <label v-for="id in activities" :key="id" class="c-mobile-filters__item" :class="'c-mobile-filters__item--'+id">
        <input type="checkbox" @change="track(id)" v-model="form.activities" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
      </label>
    </div>
    <h3 class="c-mobile-filters__subtitle">{{ $t('filters.subtitle_services') }}</h3>
    <div class="c-mobile-filters__items c-mobile-filters__items--services">
      <label v-for="id in filters" :key="id" class="c-mobile-filters__item">
        <input type="checkbox" @change="track(id)" v-model="form.filters" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
      </label>
    </div>
    <button class="c-mobile-filters__submit" :class="{'is-active':isDirty}" @click="submit" v-track-button="{location:'filters'}">{{ $t('filters.submit') }}</button>
  </div>
</template>

<script>
import {trackEvent} from "@/scripts/api";

export default {
  watch:{
    form:{
      handler() {
        if( this.form.gps )
          this.detectPosition()
      },
      deep:true
    }
  },
  computed:{
    isDirty(){
      return this.form.activities.length
    },
    form: {
      get () {
        return this.$store.state.form
      },
      set (value) {
        this.$store.commit('form', value)
      }
    }
  },
  data() {
    return{
      activities: [
        "shoes",
        "clothes"
      ],
      filters: [
        "stores",
        "services",
        "change"
      ]
    }
  },
  methods:{
    track(id){
      if( this.form.activities.indexOf(id) !== -1 || this.form.filters.indexOf(id) !== -1 )
        trackEvent('filter_selected', {filter_label:id})
    },
    submit(){
      this.$router.push({name:'home'});
    }
  }
}
</script>

<style lang="scss">
.c-mobile-filters{
  display: flex; flex-shrink: 0; grid-gap: $space-m; flex-flow: column; height: 100%;
  &__title{ @extend %title-section }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center $c-white; border-radius: 50%;
    display: inline-block; width: 3rem; height: 3rem; background-size: 60%;
    position: absolute; right: $space-s; top: $space-s; cursor: pointer; z-index: 1;
  }
  &__subtitle{ font-weight: 500 }
  &__items{
    &--repair{
      display: flex; width: 100%;
      label{ width: 100% }
      span{
        background: no-repeat center bottom $c-theme-alt; background-size: 80%; display: block; opacity: 0.4;
        flex-basis: 50%; aspect-ratio: 1; border-radius: $space; padding: $space; text-align: center; font-weight: 500;
        transition: allow(opacity outline); outline: 1px solid transparent;
      }
      input{
        position: absolute; opacity: 0; pointer-events: none;
        &:checked+span{ opacity: 1; outline-color: $c-black }
      }
    }
    &--services{
      display: flex; flex-flow: column; grid-gap: $space;
      input{
        position: absolute; opacity: 0; pointer-events: none;
        &:checked+span:after{ opacity: 1; transform: none }
      }
      span{
        display: flex; align-items: center; grid-gap: $space-s; position: relative;
        &:after{
          content: ''; display: inline-block; width: 2rem; height: 2rem; background: $c-black; border-radius: 50%;
          position: absolute; left: 0.4rem; top: 0.4rem; transition: allow(opacity transform); opacity: 0; transform: scale(0.8);
        }
        &:before{ content: ''; display: inline-block; width: 2.8rem; height: 2.8rem; border: 1px solid; border-radius: 50%}
      }
    }
  }
  &__submit{
    padding: $space; background: $c-black; color: $c-white; border-radius: $space-l; font-weight: 500;
    transition: allow(opacity); pointer-events: none; opacity: 0.5; margin-top: auto;
    &.is-active{ opacity: 1; pointer-events: all }
  }
  &__item{
    &--shoes span{
      background-image: url("../assets/picto/shoes-colored.svg"); background-color: $c-theme-alt;
    }
    &--clothes span{
      background-image: url("../assets/picto/t-shirt-colored.svg"); background-color: #2BB673;
    }
  }
}
</style>
